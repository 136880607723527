<template>
  <div class="contact">
    <!-- banner部分 -->
    <div class="contactBanner">
      <!-- banner定位部分 -->
      <div class="bannerText">
        <div class="bannerExplain">
          <div class="bannerTitle">
            {{
              this.$Base64.decode(this.$route.query.params) == 'platform'
                ? '平台入驻'
                : this.$Base64.decode(this.$route.query.params) == 'docking'
                ? '开发者对接'
                : '意见反馈'
            }}
          </div>
          <p class="text">
            {{
              this.$Base64.decode(this.$route.query.params) == 'platform'
                ? '为企业责任、为园区减负、为政府分忧'
                : this.$Base64.decode(this.$route.query.params) == 'docking'
                ? '互联互通、共同创造、达成一致目标'
                : '您的宝贵意见，会使众望智慧变的更好'
            }}
          </p>
        </div>
      </div>
    </div>
    <!-- 表单和地图部分 -->
    <div class="formMap">
      <div class="container">
        <div class="form">
          <div class="formIntroduce">
            <div class="formTitle">填写信息</div>
            <p class="tip">（请填写详细信息以方便我们及时联系您）</p>
          </div>
          <!-- 表单填写 -->
          <div class="formBox">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
              <div class="formRow">
                <div class="formItem">
                  <el-form-item label="姓名" prop="name">
                    <el-input v-model.trim="ruleForm.name"></el-input>
                  </el-form-item>
                </div>
                <div class="formItem">
                  <el-form-item label="联系方式" prop="phone">
                    <el-input v-model.trim="ruleForm.phone"></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="formRow">
                <div class="formItem">
                  <el-form-item label="公司名称" prop="orgName">
                    <el-input v-model.trim="ruleForm.orgName"></el-input>
                  </el-form-item>
                </div>
                <div class="formItem">
                  <el-form-item label="邮箱" prop="email">
                    <el-input v-model.trim="ruleForm.email"></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="formItem">
                <el-form-item label="内容">
                  <el-input type="textarea" v-model.trim="ruleForm.content"></el-input>
                </el-form-item>
              </div>
              <div class="formItem">
                <el-form-item label="验证码" prop="identifyCode">
                  <el-input v-model.trim="ruleForm.identifyCode" style="width: 100%"></el-input>
                  <div
                    @click="changeCode"
                    style="
                      z-index: 10;
                      position: relative;
                      left: 352px;
                      top: 11px;
                      cursor: pointer;
                      overflow: hidden;
                      width: 150px;
                    "
                  >
                    <!-- 引入验证码组件 -->
                    <identify
                      :identifyCode="identifyCode"
                      :contentWidth="150"
                      :contentHeight="40"
                    ></identify>
                  </div>
                </el-form-item>
              </div>
              <div class="submit">
                <el-button type="primary" @click="submitForm">提交</el-button>
              </div>
            </el-form>
          </div>
        </div>
        <div class="map">
          <!-- 地图标题 -->
          <div class="mapIntroduce">
            <p class="contactTitle">联系我们</p>
            <p class="contactUs">Contact Us</p>
          </div>
          <!-- 地图 -->
          <div class="mapContainer">
            <baidu-map class="mapBox" :center="{ lng: 104.06986, lat: 30.676778 }" :zoom="17">
              <bm-marker
                :position="{ lng: 104.06986, lat: 30.676778 }"
                :dragging="true"
                @click="infoWindowOpen"
              >
                <bm-info-window
                  :show="show"
                  @close="infoWindowClose"
                  @open="infoWindowOpen"
                  style="width: 374px; margin-top: 18px"
                  >四川省成都市青羊区青龙街51号倍特康派大厦9楼</bm-info-window
                >
              </bm-marker>
            </baidu-map>
          </div>

          <!-- 联系方式 -->
          <div class="contactInformation">
            <div class="info">
              <img :src="telIcon" alt="联系方式icon" />
              <span>黄先生 13590218573</span>
            </div>
            <div class="info">
              <img :src="emaiIcon" alt="邮件icon" />
              <span>13590218573@zwehs.com</span>
            </div>
            <div class="info">
              <img :src="addressIcon" alt="地址icon" />
              <span>四川省成都市青羊区青龙街51号倍特康派大厦9楼</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { addPlatform } from '@/api';
import identify from 'components/Identify.vue';
export default {
  components: {
    identify
  },
  data() {
    var validateName = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入姓名'));
      } else if (value.length > 10) {
        callback(new Error('限制10个字符'));
      } else {
        callback();
      }
    };
    var validatePhone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入联系方式'));
      } else if (value.length > 13) {
        callback(new Error('限制13个字符'));
      } else if (!/^([0-9-]+)$/.test(value)) {
        callback(new Error('手机号格式错误'));
      } else {
        callback();
      }
    };
    var validateOrgName = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入公司名称'));
      } else if (value.length > 50) {
        callback(new Error('限制50个字符'));
      } else {
        callback();
      }
    };
    var validateEmail = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入邮箱'));
      } else if (value.length > 50) {
        callback(new Error('限制50个字符'));
      } else {
        callback();
      }
    };
    var validateContent = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入内容'));
      } else if (value.length > 200) {
        callback(new Error('限制200个字符'));
      } else {
        callback();
      }
    };
    var validateIdentifyCode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入验证码'));
      } else if (value !== this.identifyCode) {
        callback(new Error('验证码错误（区分大小写）'));
      } else {
        callback();
      }
    };
    return {
      show: false,
      telIcon: require('assets/images/contact/tel.png'),
      emaiIcon: require('assets/images/contact/email.png'),
      addressIcon: require('assets/images/contact/address.png'),
      // 验证码初始值
      identifyCode: '1234',
      // 验证码的随机取值范围
      identifyCodes: '1234567890abcdefghijklmnopqrstuvwsyzABCDEFGHIJKLMNOPQRSTUVWSYZ',
      ruleForm: {
        name: '',
        phone: '',
        orgName: '',
        email: '',
        content: '',
        identifyCode: ''
      },
      rules: {
        name: [{ validator: validateName, trigger: 'blur' }],
        phone: [{ validator: validatePhone, trigger: 'blur' }],
        orgName: [{ validator: validateOrgName, trigger: 'blur' }],
        email: [{ validator: validateEmail, trigger: 'blur' }],
        content: [{ validator: validateContent, trigger: 'blur' }],
        identifyCode: [{ validator: validateIdentifyCode, trigger: 'blur' }]
      }
    };
  },
  methods: {
    //保存接口
    addPlatform() {
      addPlatform(this.ruleForm)
        .then(res => {
          if (res.data.code === 200) {
            this.$message({
              message: '提交成功',
              type: 'success'
            });
            this.ruleForm = {};
            // this.$refs.ruleForm.resetFields();
          } else {
            this.$message({
              message: res.data.message,
              type: 'error'
            });
          }
        })
        .catch(error => {
          this.$message({
            message: error.response.data.message,
            type: 'error'
          });
        });
    },
    infoWindowClose() {
      this.show = false;
    },
    infoWindowOpen() {
      this.show = true;
    },
    submitForm() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.addPlatform();
        }
      });
    },
    // 点击验证码刷新验证码
    changeCode() {
      this.identifyCode = '';
      this.makeCode(this.identifyCodes, 6);
    },
    // 生成一个随机整数  randomNum(0, 10) 0 到 10 的随机整数， 包含 0 和 10
    randomNum(min, max) {
      max = max + 1;
      return Math.floor(Math.random() * (max - min) + min);
    },
    // 随机生成验证码字符串
    makeCode(data, len) {
      for (let i = 0; i < len; i++) {
        this.identifyCode += data[this.randomNum(0, data.length - 1)];
      }
    }
  },
  mounted() {
    // 刷新页面就生成随机验证码
    this.identifyCode = '';
    this.makeCode(this.identifyCodes, 6);
  }
};
</script>

<style lang="less" scoped>
.contact {
  height: 100%;
  position: relative;
  .contactBanner {
    width: 100%;
    height: 502px;
    background: url('~assets/images/contact/ljty_banner.png') no-repeat center;
    background-size: cover;
    position: relative;
    .bannerText {
      position: relative;
      padding-left: 30px;
      top: 146px;
      width: 1200px;
      margin: 0 auto;
      box-sizing: border-box;
      color: #fff;
      .bannerTitle {
        font-size: 38px;
        margin-bottom: 18px;
      }
      .text {
        font-size: 18px;
      }
    }
  }
  .formMap {
    width: 1200px;
    margin: 0 auto;
    position: relative;
    top: -176px;
    .container {
      display: flex;
      height: 748px;
      width: 100%;
      .form {
        width: 700px;
        background-color: #051d3b;
        box-sizing: border-box;
        padding: 24px 50px 0 36px;
        .formIntroduce {
          display: flex;
          color: #fff;
          align-items: center;
          height: 36px;
          .formTitle {
            font-size: 18px;
            margin-right: 12px;
          }
          .tip {
            font-size: 12px;
          }
        }
        .formBox {
          color: #fff;
          margin-top: 40px;
          .formRow {
            display: flex;
            justify-content: space-between;
            /deep/.el-form-item__error {
              top: 185%;
            }
            .formItem {
              margin-bottom: 30px;
            }
          }
          .submit {
            margin-top: 64px;
            /deep/.el-button {
              width: 100%;
              background: #1d7df8;
              height: 46px;
              border-radius: 4px;
              color: #fff;
              font-size: 18px;
              box-sizing: border-box;
              border: 0;
            }
          }
        }
      }
      .map {
        width: 500px;
        border: 1px solid #ffffff;
        box-sizing: border-box;
        background-color: #fff;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16);
        padding: 24px;
        .mapIntroduce {
          .contactTitle {
            font-size: 18px;
            color: #333;
            height: 36px;
            line-height: 36px;
          }
          .contactUs {
            color: #999;
            font-size: 14px;
          }
        }
        .mapContainer {
          margin-top: 28px;
          .mapBox {
            width: 455px;
            height: 386px;
          }
        }
        .contactInformation {
          width: 100%;
          margin-top: 38px;
          .info {
            display: flex;
            align-items: center;
            color: #333;
            margin-bottom: 30px;
            img {
              margin-right: 16px;
            }
          }
        }
      }
    }
  }
}
/deep/.el-form-item__label {
  box-sizing: content-box;
  text-align: left;
  font-size: 14px;
  color: #fff;
  margin-bottom: 10px;
}
/deep/.el-form-item__content {
  margin-left: 0 !important;
}
/deep/.el-input {
  width: 294px;
  height: 40px;
  line-height: 40px;
  display: block;
  box-sizing: border-box;
  border-radius: 4px;
  border: 0;
}
/deep/.el-textarea {
  height: 120px;
  border-radius: 4px;
  .el-textarea__inner {
    height: 100%;
  }
}
</style>


