import { axios } from '@/utils/request';
/**
 * 新增
 */
export function addPlatform(data) {
  return axios({
    url: '/roses-platform/platform/addPlatform',
    method: 'post',
    data
  });
}

/**
 * 新增
 */
 export function getPlatformPage(params) {
  return axios({
    url: '/roses-platform/platform/getPlatformPage',
    method: 'get',
    params
  });
}
